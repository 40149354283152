<template>
	<div class="uu-user-info">
		<el-form label-width="120px">
			<el-form-item :label="$t('seller.formItem.account')"><span class="info-value">{{userInfo.userName}}</span>
			</el-form-item>
			<el-form-item :label="$t('seller.formItem.bindMobile')">
				<span class="info-value">{{userInfo.mobile?userInfo.mobile:$t('seller.home.unbound')}}</span>
				<!-- <el-button type="primary" plain round>{{$t('seller.actions.modifyMobile')}}</el-button> -->
			</el-form-item>
			<!-- <el-form-item :label="$t('seller.formItem.mailbox')">
				<el-button type="primary" plain round>{{$t('seller.actions.newBound')}}</el-button>
			</el-form-item>
			<el-form-item :label="$t('seller.formItem.loginPassword')">
				<el-button type="primary" plain round>{{$t('seller.actions.modifyPassword')}}</el-button>
			</el-form-item> -->
			<el-form-item :label="$t('seller.formItem.bindIdentity')">
				<template v-if="userInfo.open_shop===2">{{$t('seller.home.bound')}}</template>
				<template v-else>
					<router-link :to="{ name: 'openShop' }">
						<el-button round>{{$t('seller.actions.newBound')}}</el-button>
					</router-link>
				</template>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		}
	}
</script>

<style lang="scss" scoped>
	.uu-user-info {
		width: 500px;
		margin: 0 auto;
		padding-top: 50px;

		.info-value {
			color: #111111;
			font-size: 16px;
			margin-right: 15px;
		}

		::v-deep .el-form {
			.el-form-item__label {
				color: #999999;
				font-size: 16px;
				text-align: left;
			}
		}
	}
</style>
